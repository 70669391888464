import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import VueRouter from 'vue-router'
import ProgramTable from '@/components/ProgramTable'
import Main from '@/components/Main'

Vue.config.productionTip = false
Vue.use(ElementUI, {locale})
Vue.use(VueRouter, {})

const routes = [
  {path: '/main', component: Main},
  {path: '/list', component: ProgramTable},
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
