<template>
  <div style="width: 100%">
    <el-container>

      <el-main>
        <el-table
            v-if="!loading"
            :data="filterData(programData)"
            style="width: 100%"
            stripe
            max-height="850px">
          <el-table-column
              prop="start"
              label="Время"
              sortable
              width="180">
            <template slot-scope="scope">
              {{ convertTZ(scope.row.start) }}<br />
              <a v-if="isLive(scope.row.live)" :href="scope.row.link">
                <el-tag type="danger">live <i v-if="scope.row.link" class="el-icon-view"></i></el-tag>
              </a>
            </template>
          </el-table-column>
          <el-table-column
              width="80px"
              label="">
            <template slot-scope="scope">
              <el-image
                  :src="scope.row.icon">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
              prop="name"
              width="300px"
              label="Канал">
            <template slot-scope="scope">
              {{ scope.row.name }}<br/>
              <el-tag type="info">{{ scope.row.channel_type }}</el-tag>
            </template>
            <template v-slot:header>
              <el-select
                  v-model="selectedChannels"
                  multiple
                  collapse-tags
                  placeholder="Каналы">
                <el-option-group
                    v-for="group in channels.options"
                    :key="group.label"
                    :label="group.label">
                  <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value">
                  </el-option>
                </el-option-group>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
              prop="type_name"
              width="300px"
              label="Вид спорта">
            <template v-slot:header>
              <el-select
                  v-model="selectedSportTypes"
                  multiple
                  collapse-tags
                  placeholder="Виды спорта">
                <el-option
                    v-for="item in sportTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
              prop="title"

              label="Событие">
            <template slot-scope="scope" >
              <a class="descr" v-if="scope.row.program_link" :href="scope.row.program_link">
                {{ scope.row.title }}
              </a>
              <span class="descr" v-else>{{ scope.row.title }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ProgramTable.vue',
  data () {
    return {
      programData: [],
      loading: true,
      channels: [],
      sportTypes: [],
      selectedChannels: [],
      selectedSportTypes: [],
      host: 'https://tv.kmb-5.ru/',
      //host: 'http://localhost/tv/',
    }
  },
  mounted () {
    this.refreshData()
    this.startTimer()
  },
  beforeDestroy () {
    this.stopTimer()
  },
  create () {
    //this.refreshChannels()
  },

  methods: {
    refreshData () {
      this.refreshTable()
      this.refreshChannels()
    },
    convertTZ (date, tzString) {
      if (!tzString) {
        tzString = Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      date = date + ' +0300'
      let d
      d = new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {timeZone: tzString}))
      return d.toLocaleString('ru-RU', {timeStyle: 'short', dateStyle: 'short'})
    },
    refreshTable () {
      this.loading = true
      axios.get(this.host + 'tv.php?q=programs').then(
          response => (this.programData = response.data.data),
      )
      this.loading = false
    },
    filterData (dataTable) {
      return dataTable.filter(
          row => (this.selectedSportTypes.includes(row['type_name']) || this.selectedSportTypes.length === 0) &&
              (this.selectedChannels.includes(row['name']) || this.selectedChannels.length === 0))

    },

    isLive (live) {
      return live === '1'
    },

    refreshChannels () {
      axios.get(this.host + 'tv.php?q=channels').then(
          response => (this.channels = response.data),
      )
      axios.get(this.host + 'tv.php?q=sportTypes').then(
          response => (this.sportTypes = response.data.data),
      )
    },
    stopTimer () {
      if (this.interval) {
        window.clearInterval(this.interval)
      }
    },
    startTimer () {
      this.stopTimer()
      this.interval = window.setInterval(() => {
        this.refreshData()
      }, 10000)
    },
  },
}
</script>

<style>
.el-image {
  zoom: 0.5;
}

.descr {
  word-break: break-word;
}

.el-table .success-row {
  background: #67C23A;
}
</style>
