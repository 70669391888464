<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-carousel height="150px">
          <el-carousel-item v-for="item in 4" :key="item">
            <h3 class="small">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>

    <el-row v-for="i in Math.ceil(programData.length / 6)" :key="i">
      <el-col :span="4" v-for="data in programData.slice((i - 1) * 6, i * 6)" :key="data.title + data.start + data.name">
        <el-card style="height: 420px;" shadow="false">
          <header>{{ data.type_name }}</header>
          <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
               class="image" alt="">
          <div style="padding-top: 14px;">
              {{ data.title }}<br />
              {{ data.start }}<br />
              <el-tag type="info">
                {{ data.name }}
              </el-tag>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ProgramTable.vue',
  data () {
    return {
      programData: [],
      loading: true,
      channels: [],
      sportTypes: [],
      selectedChannels: [],
      selectedSportTypes: [],
      host: 'https://tv.kmb-5.ru/',
      //host: 'http://localhost/tv/',
    }
  },
  mounted () {
    this.refreshTable()
  },
  create () {

  },
  methods: {
    refreshTable () {
      this.loading = true
      axios.get(this.host + 'tv.php?q=programs&limit=12').then(
          response => (this.programData = response.data.data),
      )
      this.loading = false
    },
  },
}
</script>

<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
