<template>
  <div id="app">
    <el-container style="border: 1px solid #eee">
      <el-aside>
        <el-menu
            default-active="1"
            class="el-menu-vertical-demo">
          <router-link to="/main">
            <el-menu-item index="1">
              <i class="el-icon-tickets"></i>
              <span>Обзор</span>
            </el-menu-item>
          </router-link>

          <router-link to="/list">
            <el-menu-item index="2">
              <i class="el-icon-tickets"></i>
              <span>Программа</span>
            </el-menu-item>
          </router-link>

          <el-menu-item index="3" to="/dict">
            <i class="el-icon-menu"></i>
            <span>Справочники</span>
          </el-menu-item>
          <el-menu-item index="4" to="/options">
            <i class="el-icon-setting"></i>
            <span>Настройки</span>
          </el-menu-item>
        </el-menu>
        <span>{{ timezone }}</span>
      </el-aside>
      <el-main>
        <router-view></router-view>
        <!--        <ProgramTable/>-->
      </el-main>
    </el-container>
  </div>
</template>

<script>
//import ProgramTable from './components/ProgramTable'

export default {
  name: 'App',
  components: {
    //ProgramTable,
  },
  data () {
    return {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }
}
</script>

<style>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.el-main {
  padding: 0;
}
</style>
